@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer base { */
:root {
  --background: 0 0% 100%;
  --foreground: 222.2 47.4% 11.2%;

  --muted: 0 0% 96.1%;
  --muted-foreground: 215.4 16.3% 46.9%;

  --popover: 0 0% 100%;
  --popover-foreground: 222.2 47.4% 11.2%;

  --card: 0 0% 94%;
  --card-foreground: 222.2 47.4% 11.2%;

  --border: 0 0% 91.4%;
  --input: 214.3 31.8% 91.4%;

  --primary: 222.2 47.4% 11.2%;
  --primary-foreground: 210 40% 98%;

  --secondary: 210 40% 96.1%;
  --secondary-foreground: 222.2 47.4% 11.2%;

  --accent: 210 40% 96.1%;
  --accent-foreground: 222.2 47.4% 11.2%;

  --destructive: 0 100% 50%;
  --destructive-foreground: 210 40% 98%;

  --ring: 215 20.2% 65.1%;

  --radius: 0.5rem;
  /* 
  --surface-lowest: 0 0% 100%;
  --surface-low: 72 33.333% 94.12%;
  --surface-container: 72 23.81% 91.76%;
  --surface-high: 72 18.52% 89.41%;
  --surface-highest: 72 15.15% 87.06%; */

  /* --surface-highest: 0 0% 100%;
  --surface-high: 72 33.333% 94.12%;
  --surface-container: 72 23.81% 91.76%;
  --surface-low: 72 18.52% 89.41%;
  --surface-lowest: 72 15.15% 87.06%; */

  /* --primary: 72 15.15% 30%; */

  /* --surface-lowest: 0 0% 100%;
  --surface-low: 278 44% 96%;
  --surface-container: 276 38% 95%;
  --surface-high: 276 25% 92%;
  --surface-highest: 280 17% 90%;
  --surface: 293 100% 98%; */

  /* --surface-highest: 0 0% 100%;
  --surface-high: 278 44% 96%;
  --surface-container: 276 38% 95%;
  --surface-low: 276 25% 92%;
  --surface-lowest: 280 17% 90%; */

  /* --surface-lowest: 0 0% 100%;
  --surface-low: 72 33% 94%;
  --surface-container: 72 23.81% 91.76%;
  --surface-high: 72 18.52% 89.41%;
  --surface-highest: 72 15.15% 87.06%; */

  /* --surface-lowest: 0 0% 100%;
  --surface-low: 0 0% 95%;
  --surface-container: 0 0% 92%;
  --surface-high: 0 0% 90%;
  --surface-highest: 0 0% 88%; */

  /* --surface: 293 100% 98%;

  --primary: 264 8% 1%; */

  /* --surface-lowest: 0 0% 100%;
  --surface-low: 72 19% 95%;
  --surface-container: 72 13% 92%;
  --surface-high: 80 12% 90%;
  --surface-highest: 72 8% 88%; */

  --surface-lowest: var(--md-sys-color-surface-container-lowest-light);
  --surface-low: var(--md-sys-color-surface-container-low-light);
  --surface-container: var(--md-sys-color-surface-container-light);
  --surface-high: var(--md-sys-color-surface-container-high-light);
  --surface-highest: var(--md-sys-color-surface-container-highest-light);
  --surface-bright: var(--md-sys-color-surface-bright-dark);
}

html.dark {
  /* :root { */
  /* --background: 260 5.26% 11.18%; */
  --background: 260 5.26% 11.18%;
  --foreground: 213 31% 91%;

  --muted: 260 5.26% 15%;
  --muted-foreground: 260 5.26% 56.9%;

  --popover: 260 5.26% 4%;
  --popover-foreground: 260 5.26% 65.1%;

  --card: 260 5.26% 16%;
  --card-foreground: 260 5.26% 91%;

  --border: 260 5.26% 25%;
  --input: 260 5.26% 17%;

  --primary: 260 5.26% 98%;
  --primary-foreground: 260 5.26% 1.2%;

  --secondary: 260 5.26% 15.2%;
  --secondary-foreground: 260 5.26% 98%;

  --accent: 260 5.26% 17%;
  --accent-foreground: 260 5.26% 98%;

  --destructive: 0 63% 31%;
  --destructive-foreground: 210 40% 98%;

  --ring: 260 5.26% 17%;

  --radius: 0.5rem;

  /* --surface-lowest: 260 5.26% 11%;
  --surface-low: 260 5.26% 14%;
  --surface-container: 260 5.26% 17%;
  --surface-high: 260 5.26% 20%;
  --surface-highest: 260 5.26% 23%; */

  --surface-lowest: var(--md-sys-color-surface-container-lowest-dark);
  --surface-low: var(--md-sys-color-surface-container-low-dark);
  --surface-container: var(--md-sys-color-surface-container-dark);
  --surface-high: var(--md-sys-color-surface-container-high-dark);
  --surface-highest: var(--md-sys-color-surface-container-highest-dark);
  --surface-bright: var(--md-sys-color-surface-bright-dark);
}
/* } */

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply antialiased;
    /* @apply bg-background text-foreground; */
    @apply md:bg-surface-highest md:dark:bg-surface-lowest bg-surface-container text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .hide-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .hide-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

.fade-in {
  animation-name: fade-in-animation;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  animation-delay: 0.2s;
  opacity: 0;
}

.fade-in-left {
  animation-name: fade-in-left-animation;
  animation-duration: 0.15s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  /* animation-delay: 0.2s; */
  /* animation-delay: 0.15s; */
  animation-delay: 0.05s;
  opacity: 0;
}

.fade-in-without-delay {
  animation-name: fade-in-animation;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  opacity: 0;
}

@keyframes fade-in-left-animation {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes fade-in-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.check-answer {
  position: relative;
}

.check-answer:after {
  content: "";
  position: absolute;
  left: -21px;
  height: 2px;
  width: 21px;
  background: #353438;
  top: 11px;
}
.check-answer:before {
  content: "";
  position: absolute;
  left: -21px;
  height: 19px;
  width: 2px;
  background: #353438;
  top: -8px;
}

:root {
  --md-source: #eeeeee;
  /* primary */
  --md-ref-palette-primary0: #000000;
  --md-ref-palette-primary4: #001114;
  --md-ref-palette-primary5: #001417;
  --md-ref-palette-primary6: #00161a;
  --md-ref-palette-primary10: #001f24;
  --md-ref-palette-primary12: #002429;
  --md-ref-palette-primary17: #002f35;
  --md-ref-palette-primary20: #00363d;
  --md-ref-palette-primary22: #003b42;
  --md-ref-palette-primary24: #004047;
  --md-ref-palette-primary25: #00424a;
  --md-ref-palette-primary30: #004f58;
  --md-ref-palette-primary35: #005b66;
  --md-ref-palette-primary40: #006874;
  --md-ref-palette-primary50: #008391;
  --md-ref-palette-primary60: #00a0b0;
  --md-ref-palette-primary70: #22bccf;
  --md-ref-palette-primary80: #4fd8eb;
  --md-ref-palette-primary87: #68ecff;
  --md-ref-palette-primary90: #97f0ff;
  --md-ref-palette-primary92: #aff3ff;
  --md-ref-palette-primary94: #c5f6ff;
  --md-ref-palette-primary95: #d0f8ff;
  --md-ref-palette-primary96: #daf9ff;
  --md-ref-palette-primary98: #edfcff;
  --md-ref-palette-primary99: #f6feff;
  --md-ref-palette-primary100: #ffffff;
  /* secondary */
  --md-ref-palette-secondary0: #000000;
  --md-ref-palette-secondary4: #001114;
  --md-ref-palette-secondary5: #001417;
  --md-ref-palette-secondary6: #00161a;
  --md-ref-palette-secondary10: #051f23;
  --md-ref-palette-secondary12: #092327;
  --md-ref-palette-secondary17: #152e31;
  --md-ref-palette-secondary20: #1c3438;
  --md-ref-palette-secondary22: #20383c;
  --md-ref-palette-secondary24: #253d41;
  --md-ref-palette-secondary25: #273f43;
  --md-ref-palette-secondary30: #334b4f;
  --md-ref-palette-secondary35: #3e565b;
  --md-ref-palette-secondary40: #4a6267;
  --md-ref-palette-secondary50: #637b80;
  --md-ref-palette-secondary60: #7c959a;
  --md-ref-palette-secondary70: #96b0b4;
  --md-ref-palette-secondary80: #b1cbd0;
  --md-ref-palette-secondary87: #c4dfe4;
  --md-ref-palette-secondary90: #cde7ec;
  --md-ref-palette-secondary92: #d2edf2;
  --md-ref-palette-secondary94: #d8f3f7;
  --md-ref-palette-secondary95: #dbf6fa;
  --md-ref-palette-secondary96: #def8fd;
  --md-ref-palette-secondary98: #edfcff;
  --md-ref-palette-secondary99: #f6feff;
  --md-ref-palette-secondary100: #ffffff;
  /* tertiary */
  --md-ref-palette-tertiary0: #000000;
  --md-ref-palette-tertiary4: #010d29;
  --md-ref-palette-tertiary5: #030f2c;
  --md-ref-palette-tertiary6: #05122e;
  --md-ref-palette-tertiary10: #0e1b37;
  --md-ref-palette-tertiary12: #121f3b;
  --md-ref-palette-tertiary17: #1d2946;
  --md-ref-palette-tertiary20: #24304d;
  --md-ref-palette-tertiary22: #283451;
  --md-ref-palette-tertiary24: #2d3856;
  --md-ref-palette-tertiary25: #2f3b58;
  --md-ref-palette-tertiary30: #3b4664;
  --md-ref-palette-tertiary35: #465271;
  --md-ref-palette-tertiary40: #525e7d;
  --md-ref-palette-tertiary50: #6b7697;
  --md-ref-palette-tertiary60: #8490b2;
  --md-ref-palette-tertiary70: #9faace;
  --md-ref-palette-tertiary80: #bac6ea;
  --md-ref-palette-tertiary87: #ced9ff;
  --md-ref-palette-tertiary90: #dae2ff;
  --md-ref-palette-tertiary92: #e2e7ff;
  --md-ref-palette-tertiary94: #eaedff;
  --md-ref-palette-tertiary95: #eef0ff;
  --md-ref-palette-tertiary96: #f2f3ff;
  --md-ref-palette-tertiary98: #faf8ff;
  --md-ref-palette-tertiary99: #fefbff;
  --md-ref-palette-tertiary100: #ffffff;
  /* neutral */
  --md-ref-palette-neutral0: #000000;
  --md-ref-palette-neutral4: #0b0f0f;
  --md-ref-palette-neutral5: #0e1212;
  --md-ref-palette-neutral6: #101415;
  --md-ref-palette-neutral10: #191c1d;
  --md-ref-palette-neutral12: #1d2021;
  --md-ref-palette-neutral17: #272b2b;
  --md-ref-palette-neutral20: #2e3132;
  --md-ref-palette-neutral22: #323536;
  --md-ref-palette-neutral24: #363a3a;
  --md-ref-palette-neutral25: #393c3d;
  --md-ref-palette-neutral30: #444748;
  --md-ref-palette-neutral35: #505354;
  --md-ref-palette-neutral40: #5c5f5f;
  --md-ref-palette-neutral50: #747878;
  --md-ref-palette-neutral60: #8e9192;
  --md-ref-palette-neutral70: #a9acac;
  --md-ref-palette-neutral80: #c4c7c7;
  --md-ref-palette-neutral87: #d8dadb;
  --md-ref-palette-neutral90: #e1e3e3;
  --md-ref-palette-neutral92: #e6e8e9;
  --md-ref-palette-neutral94: #eceeef;
  --md-ref-palette-neutral95: #eff1f1;
  --md-ref-palette-neutral96: #f2f4f4;
  --md-ref-palette-neutral98: #f8fafa;
  --md-ref-palette-neutral99: #fafdfd;
  --md-ref-palette-neutral100: #ffffff;
  /* neutral-variant */
  --md-ref-palette-neutral-variant0: #000000;
  --md-ref-palette-neutral-variant4: #071011;
  --md-ref-palette-neutral-variant5: #091214;
  --md-ref-palette-neutral-variant6: #0c1516;
  --md-ref-palette-neutral-variant10: #141d1f;
  --md-ref-palette-neutral-variant12: #182123;
  --md-ref-palette-neutral-variant17: #222b2d;
  --md-ref-palette-neutral-variant20: #293234;
  --md-ref-palette-neutral-variant22: #2d3638;
  --md-ref-palette-neutral-variant24: #323b3c;
  --md-ref-palette-neutral-variant25: #343d3f;
  --md-ref-palette-neutral-variant30: #3f484a;
  --md-ref-palette-neutral-variant35: #4b5456;
  --md-ref-palette-neutral-variant40: #576062;
  --md-ref-palette-neutral-variant50: #6f797a;
  --md-ref-palette-neutral-variant60: #899294;
  --md-ref-palette-neutral-variant70: #a3adaf;
  --md-ref-palette-neutral-variant80: #bfc8ca;
  --md-ref-palette-neutral-variant87: #d2dcde;
  --md-ref-palette-neutral-variant90: #dbe4e6;
  --md-ref-palette-neutral-variant92: #e0eaec;
  --md-ref-palette-neutral-variant94: #e6f0f1;
  --md-ref-palette-neutral-variant95: #e9f2f4;
  --md-ref-palette-neutral-variant96: #ecf5f7;
  --md-ref-palette-neutral-variant98: #f2fbfd;
  --md-ref-palette-neutral-variant99: #f6feff;
  --md-ref-palette-neutral-variant100: #ffffff;
  /* error */
  --md-ref-palette-error0: #000000;
  --md-ref-palette-error4: #280001;
  --md-ref-palette-error5: #2d0001;
  --md-ref-palette-error6: #310001;
  --md-ref-palette-error10: #410002;
  --md-ref-palette-error12: #490002;
  --md-ref-palette-error17: #5c0004;
  --md-ref-palette-error20: #690005;
  --md-ref-palette-error22: #710005;
  --md-ref-palette-error24: #790006;
  --md-ref-palette-error25: #7e0007;
  --md-ref-palette-error30: #93000a;
  --md-ref-palette-error35: #a80710;
  --md-ref-palette-error40: #ba1a1a;
  --md-ref-palette-error50: #de3730;
  --md-ref-palette-error60: #ff5449;
  --md-ref-palette-error70: #ff897d;
  --md-ref-palette-error80: #ffb4ab;
  --md-ref-palette-error87: #ffcfc9;
  --md-ref-palette-error90: #ffdad6;
  --md-ref-palette-error92: #ffe2de;
  --md-ref-palette-error94: #ffe9e6;
  --md-ref-palette-error95: #ffedea;
  --md-ref-palette-error96: #fff0ee;
  --md-ref-palette-error98: #fff8f7;
  --md-ref-palette-error99: #fffbff;
  --md-ref-palette-error100: #ffffff;
  /* light */
  --md-sys-color-primary-light: #006874;
  --md-sys-color-on-primary-light: #ffffff;
  --md-sys-color-primary-container-light: #97f0ff;
  --md-sys-color-on-primary-container-light: #001f24;
  --md-sys-color-primary-fixed-light: #97f0ff;
  --md-sys-color-on-primary-fixed-light: #001f24;
  --md-sys-color-primary-fixed-dim-light: #4fd8eb;
  --md-sys-color-on-primary-fixed-variant-light: #004f58;
  --md-sys-color-secondary-light: #4a6267;
  --md-sys-color-on-secondary-light: #ffffff;
  --md-sys-color-secondary-container-light: #cde7ec;
  --md-sys-color-on-secondary-container-light: #051f23;
  --md-sys-color-secondary-fixed-light: #cde7ec;
  --md-sys-color-on-secondary-fixed-light: #051f23;
  --md-sys-color-secondary-fixed-dim-light: #b1cbd0;
  --md-sys-color-on-secondary-fixed-variant-light: #334b4f;
  --md-sys-color-tertiary-light: #525e7d;
  --md-sys-color-on-tertiary-light: #ffffff;
  --md-sys-color-tertiary-container-light: #dae2ff;
  --md-sys-color-on-tertiary-container-light: #0e1b37;
  --md-sys-color-tertiary-fixed-light: #dae2ff;
  --md-sys-color-on-tertiary-fixed-light: #0e1b37;
  --md-sys-color-tertiary-fixed-dim-light: #bac6ea;
  --md-sys-color-on-tertiary-fixed-variant-light: #3b4664;
  --md-sys-color-error-light: #ba1a1a;
  --md-sys-color-error-container-light: #ffdad6;
  --md-sys-color-on-error-light: #ffffff;
  --md-sys-color-on-error-container-light: #410002;
  --md-sys-color-background-light: #fafdfd;
  --md-sys-color-on-background-light: #191c1d;
  --md-sys-color-outline-light: #6f797a;
  --md-sys-color-inverse-on-surface-light: #eff1f1;
  --md-sys-color-inverse-surface-light: #2e3132;
  --md-sys-color-inverse-primary-light: #4fd8eb;
  --md-sys-color-shadow-light: #000000;
  --md-sys-color-surface-tint-light: #006874;
  --md-sys-color-outline-variant-light: #bfc8ca;
  --md-sys-color-scrim-light: #000000;
  --md-sys-color-surface-light: #f8fafa;
  --md-sys-color-on-surface-light: #191c1d;
  --md-sys-color-surface-variant-light: #dbe4e6;
  --md-sys-color-on-surface-variant-light: #3f484a;
  --md-sys-color-surface-container-highest-light: #e1e3e3;
  --md-sys-color-surface-container-high-light: #e6e8e9;
  --md-sys-color-surface-container-light: #eceeef;
  --md-sys-color-surface-container-low-light: #f2f4f4;
  --md-sys-color-surface-container-lowest-light: #ffffff;
  --md-sys-color-surface-dim-light: #d8dadb;
  --md-sys-color-surface-bright-light: #f8fafa;
  /* dark */
  --md-sys-color-primary-dark: #4fd8eb;
  --md-sys-color-on-primary-dark: #00363d;
  --md-sys-color-primary-container-dark: #004f58;
  --md-sys-color-on-primary-container-dark: #97f0ff;
  --md-sys-color-primary-fixed-dark: #97f0ff;
  --md-sys-color-on-primary-fixed-dark: #001f24;
  --md-sys-color-primary-fixed-dim-dark: #4fd8eb;
  --md-sys-color-on-primary-fixed-variant-dark: #004f58;
  --md-sys-color-secondary-dark: #b1cbd0;
  --md-sys-color-on-secondary-dark: #1c3438;
  --md-sys-color-secondary-container-dark: #334b4f;
  --md-sys-color-on-secondary-container-dark: #cde7ec;
  --md-sys-color-secondary-fixed-dark: #cde7ec;
  --md-sys-color-on-secondary-fixed-dark: #051f23;
  --md-sys-color-secondary-fixed-dim-dark: #b1cbd0;
  --md-sys-color-on-secondary-fixed-variant-dark: #334b4f;
  --md-sys-color-tertiary-dark: #bac6ea;
  --md-sys-color-on-tertiary-dark: #24304d;
  --md-sys-color-tertiary-container-dark: #3b4664;
  --md-sys-color-on-tertiary-container-dark: #dae2ff;
  --md-sys-color-tertiary-fixed-dark: #dae2ff;
  --md-sys-color-on-tertiary-fixed-dark: #0e1b37;
  --md-sys-color-tertiary-fixed-dim-dark: #bac6ea;
  --md-sys-color-on-tertiary-fixed-variant-dark: #3b4664;
  --md-sys-color-error-dark: #ffb4ab;
  --md-sys-color-error-container-dark: #93000a;
  --md-sys-color-on-error-dark: #690005;
  --md-sys-color-on-error-container-dark: #ffdad6;
  --md-sys-color-background-dark: #191c1d;
  --md-sys-color-on-background-dark: #e1e3e3;
  --md-sys-color-outline-dark: #899294;
  --md-sys-color-inverse-on-surface-dark: #191c1d;
  --md-sys-color-inverse-surface-dark: #e1e3e3;
  --md-sys-color-inverse-primary-dark: #006874;
  --md-sys-color-shadow-dark: #000000;
  --md-sys-color-surface-tint-dark: #4fd8eb;
  --md-sys-color-outline-variant-dark: #3f484a;
  --md-sys-color-scrim-dark: #000000;
  --md-sys-color-surface-dark: #101415;
  --md-sys-color-on-surface-dark: #c4c7c7;
  --md-sys-color-surface-variant-dark: #3f484a;
  --md-sys-color-on-surface-variant-dark: #bfc8ca;
  --md-sys-color-surface-container-highest-dark: #323536;
  --md-sys-color-surface-container-high-dark: #272b2b;
  --md-sys-color-surface-container-dark: #1d2021;
  --md-sys-color-surface-container-low-dark: #191c1d;
  --md-sys-color-surface-container-lowest-dark: #0b0f0f;
  --md-sys-color-surface-dim-dark: #101415;
  --md-sys-color-surface-bright-dark: #363a3a;
  /* display - large */
  --md-sys-typescale-display-large-font-family-name: Roboto;
  --md-sys-typescale-display-large-font-family-style: Regular;
  --md-sys-typescale-display-large-font-weight: 400px;
  --md-sys-typescale-display-large-font-size: 57px;
  --md-sys-typescale-display-large-line-height: 64px;
  --md-sys-typescale-display-large-letter-spacing: -0.25px;
  /* display - medium */
  --md-sys-typescale-display-medium-font-family-name: Roboto;
  --md-sys-typescale-display-medium-font-family-style: Regular;
  --md-sys-typescale-display-medium-font-weight: 400px;
  --md-sys-typescale-display-medium-font-size: 45px;
  --md-sys-typescale-display-medium-line-height: 52px;
  --md-sys-typescale-display-medium-letter-spacing: 0px;
  /* display - small */
  --md-sys-typescale-display-small-font-family-name: Roboto;
  --md-sys-typescale-display-small-font-family-style: Regular;
  --md-sys-typescale-display-small-font-weight: 400px;
  --md-sys-typescale-display-small-font-size: 36px;
  --md-sys-typescale-display-small-line-height: 44px;
  --md-sys-typescale-display-small-letter-spacing: 0px;
  /* headline - large */
  --md-sys-typescale-headline-large-font-family-name: Roboto;
  --md-sys-typescale-headline-large-font-family-style: Regular;
  --md-sys-typescale-headline-large-font-weight: 400px;
  --md-sys-typescale-headline-large-font-size: 32px;
  --md-sys-typescale-headline-large-line-height: 40px;
  --md-sys-typescale-headline-large-letter-spacing: 0px;
  /* headline - medium */
  --md-sys-typescale-headline-medium-font-family-name: Roboto;
  --md-sys-typescale-headline-medium-font-family-style: Regular;
  --md-sys-typescale-headline-medium-font-weight: 400px;
  --md-sys-typescale-headline-medium-font-size: 28px;
  --md-sys-typescale-headline-medium-line-height: 36px;
  --md-sys-typescale-headline-medium-letter-spacing: 0px;
  /* headline - small */
  --md-sys-typescale-headline-small-font-family-name: Roboto;
  --md-sys-typescale-headline-small-font-family-style: Regular;
  --md-sys-typescale-headline-small-font-weight: 400px;
  --md-sys-typescale-headline-small-font-size: 24px;
  --md-sys-typescale-headline-small-line-height: 32px;
  --md-sys-typescale-headline-small-letter-spacing: 0px;
  /* body - large */
  --md-sys-typescale-body-large-font-family-name: Roboto;
  --md-sys-typescale-body-large-font-family-style: Regular;
  --md-sys-typescale-body-large-font-weight: 400px;
  --md-sys-typescale-body-large-font-size: 16px;
  --md-sys-typescale-body-large-line-height: 24px;
  --md-sys-typescale-body-large-letter-spacing: 0.5px;
  /* body - medium */
  --md-sys-typescale-body-medium-font-family-name: Roboto;
  --md-sys-typescale-body-medium-font-family-style: Regular;
  --md-sys-typescale-body-medium-font-weight: 400px;
  --md-sys-typescale-body-medium-font-size: 14px;
  --md-sys-typescale-body-medium-line-height: 20px;
  --md-sys-typescale-body-medium-letter-spacing: 0.25px;
  /* body - small */
  --md-sys-typescale-body-small-font-family-name: Roboto;
  --md-sys-typescale-body-small-font-family-style: Regular;
  --md-sys-typescale-body-small-font-weight: 400px;
  --md-sys-typescale-body-small-font-size: 12px;
  --md-sys-typescale-body-small-line-height: 16px;
  --md-sys-typescale-body-small-letter-spacing: 0.4px;
  /* label - large */
  --md-sys-typescale-label-large-font-family-name: Roboto;
  --md-sys-typescale-label-large-font-family-style: Medium;
  --md-sys-typescale-label-large-font-weight: 500px;
  --md-sys-typescale-label-large-font-size: 14px;
  --md-sys-typescale-label-large-line-height: 20px;
  --md-sys-typescale-label-large-letter-spacing: 0.1px;
  /* label - medium */
  --md-sys-typescale-label-medium-font-family-name: Roboto;
  --md-sys-typescale-label-medium-font-family-style: Medium;
  --md-sys-typescale-label-medium-font-weight: 500px;
  --md-sys-typescale-label-medium-font-size: 12px;
  --md-sys-typescale-label-medium-line-height: 16px;
  --md-sys-typescale-label-medium-letter-spacing: 0.5px;
  /* label - small */
  --md-sys-typescale-label-small-font-family-name: Roboto;
  --md-sys-typescale-label-small-font-family-style: Medium;
  --md-sys-typescale-label-small-font-weight: 500px;
  --md-sys-typescale-label-small-font-size: 11px;
  --md-sys-typescale-label-small-line-height: 16px;
  --md-sys-typescale-label-small-letter-spacing: 0.5px;
  /* title - large */
  --md-sys-typescale-title-large-font-family-name: Roboto;
  --md-sys-typescale-title-large-font-family-style: Regular;
  --md-sys-typescale-title-large-font-weight: 400px;
  --md-sys-typescale-title-large-font-size: 22px;
  --md-sys-typescale-title-large-line-height: 28px;
  --md-sys-typescale-title-large-letter-spacing: 0px;
  /* title - medium */
  --md-sys-typescale-title-medium-font-family-name: Roboto;
  --md-sys-typescale-title-medium-font-family-style: Medium;
  --md-sys-typescale-title-medium-font-weight: 500px;
  --md-sys-typescale-title-medium-font-size: 16px;
  --md-sys-typescale-title-medium-line-height: 24px;
  --md-sys-typescale-title-medium-letter-spacing: 0.15px;
  /* title - small */
  --md-sys-typescale-title-small-font-family-name: Roboto;
  --md-sys-typescale-title-small-font-family-style: Medium;
  --md-sys-typescale-title-small-font-weight: 500px;
  --md-sys-typescale-title-small-font-size: 14px;
  --md-sys-typescale-title-small-line-height: 20px;
  --md-sys-typescale-title-small-letter-spacing: 0.1px;
}

:root {
  --md-source: #28272b;
  /* primary */
  --md-ref-palette-primary0: #000000;
  --md-ref-palette-primary4: #0a0041;
  --md-ref-palette-primary5: #0c0049;
  --md-ref-palette-primary6: #0e004f;
  --md-ref-palette-primary10: #160362;
  --md-ref-palette-primary12: #1a0b66;
  --md-ref-palette-primary17: #251a70;
  --md-ref-palette-primary20: #2c2276;
  --md-ref-palette-primary22: #30277b;
  --md-ref-palette-primary24: #352c7f;
  --md-ref-palette-primary25: #372f82;
  --md-ref-palette-primary30: #433b8e;
  --md-ref-palette-primary35: #4f479b;
  --md-ref-palette-primary40: #5b53a8;
  --md-ref-palette-primary50: #746cc3;
  --md-ref-palette-primary60: #8e86de;
  --md-ref-palette-primary70: #a8a1fb;
  --md-ref-palette-primary80: #c6c0ff;
  --md-ref-palette-primary87: #dbd5ff;
  --md-ref-palette-primary90: #e4dfff;
  --md-ref-palette-primary92: #eae5ff;
  --md-ref-palette-primary94: #f0ebff;
  --md-ref-palette-primary95: #f3eeff;
  --md-ref-palette-primary96: #f6f1ff;
  --md-ref-palette-primary98: #fcf8ff;
  --md-ref-palette-primary99: #fffbff;
  --md-ref-palette-primary100: #ffffff;
  /* secondary */
  --md-ref-palette-secondary0: #000000;
  --md-ref-palette-secondary4: #0d0c1e;
  --md-ref-palette-secondary5: #100f20;
  --md-ref-palette-secondary6: #131123;
  --md-ref-palette-secondary10: #1b192c;
  --md-ref-palette-secondary12: #1f1d30;
  --md-ref-palette-secondary17: #2a283b;
  --md-ref-palette-secondary20: #302e41;
  --md-ref-palette-secondary22: #343346;
  --md-ref-palette-secondary24: #39374b;
  --md-ref-palette-secondary25: #3b394d;
  --md-ref-palette-secondary30: #474459;
  --md-ref-palette-secondary35: #525065;
  --md-ref-palette-secondary40: #5f5c71;
  --md-ref-palette-secondary50: #77748b;
  --md-ref-palette-secondary60: #918ea5;
  --md-ref-palette-secondary70: #aca8c0;
  --md-ref-palette-secondary80: #c8c3dc;
  --md-ref-palette-secondary87: #dcd7f0;
  --md-ref-palette-secondary90: #e4dff9;
  --md-ref-palette-secondary92: #eae5ff;
  --md-ref-palette-secondary94: #f0ebff;
  --md-ref-palette-secondary95: #f3eeff;
  --md-ref-palette-secondary96: #f6f1ff;
  --md-ref-palette-secondary98: #fcf8ff;
  --md-ref-palette-secondary99: #fffbff;
  --md-ref-palette-secondary100: #ffffff;
  /* tertiary */
  --md-ref-palette-tertiary0: #000000;
  --md-ref-palette-tertiary4: #1f0414;
  --md-ref-palette-tertiary5: #230617;
  --md-ref-palette-tertiary6: #26091a;
  --md-ref-palette-tertiary10: #2f1122;
  --md-ref-palette-tertiary12: #341526;
  --md-ref-palette-tertiary17: #401f31;
  --md-ref-palette-tertiary20: #482537;
  --md-ref-palette-tertiary22: #4d2a3c;
  --md-ref-palette-tertiary24: #512e40;
  --md-ref-palette-tertiary25: #543043;
  --md-ref-palette-tertiary30: #613b4e;
  --md-ref-palette-tertiary35: #6e475a;
  --md-ref-palette-tertiary40: #7b5266;
  --md-ref-palette-tertiary50: #966b7f;
  --md-ref-palette-tertiary60: #b28499;
  --md-ref-palette-tertiary70: #ce9eb4;
  --md-ref-palette-tertiary80: #ebb8cf;
  --md-ref-palette-tertiary87: #ffcce3;
  --md-ref-palette-tertiary90: #ffd8e8;
  --md-ref-palette-tertiary92: #ffe0ec;
  --md-ref-palette-tertiary94: #ffe8f0;
  --md-ref-palette-tertiary95: #ffecf2;
  --md-ref-palette-tertiary96: #fff0f4;
  --md-ref-palette-tertiary98: #fff8f8;
  --md-ref-palette-tertiary99: #fffbff;
  --md-ref-palette-tertiary100: #ffffff;
  /* neutral */
  --md-ref-palette-neutral0: #000000;
  --md-ref-palette-neutral4: #0e0e11;
  --md-ref-palette-neutral5: #111014;
  --md-ref-palette-neutral6: #141316;
  --md-ref-palette-neutral10: #1c1b1f;
  --md-ref-palette-neutral12: #201f23;
  --md-ref-palette-neutral17: #2a292d;
  --md-ref-palette-neutral20: #313034;
  --md-ref-palette-neutral22: #353438;
  --md-ref-palette-neutral24: #3a393c;
  --md-ref-palette-neutral25: #3c3b3f;
  --md-ref-palette-neutral30: #48464a;
  --md-ref-palette-neutral35: #535256;
  --md-ref-palette-neutral40: #5f5e62;
  --md-ref-palette-neutral50: #78767a;
  --md-ref-palette-neutral60: #929094;
  --md-ref-palette-neutral70: #adaaaf;
  --md-ref-palette-neutral80: #c9c5ca;
  --md-ref-palette-neutral87: #ddd9dd;
  --md-ref-palette-neutral90: #e5e1e6;
  --md-ref-palette-neutral92: #ebe7ec;
  --md-ref-palette-neutral94: #f1ecf1;
  --md-ref-palette-neutral95: #f4eff4;
  --md-ref-palette-neutral96: #f7f2f7;
  --md-ref-palette-neutral98: #fcf8fd;
  --md-ref-palette-neutral99: #fffbff;
  --md-ref-palette-neutral100: #ffffff;
  /* neutral-variant */
  --md-ref-palette-neutral-variant0: #000000;
  --md-ref-palette-neutral-variant4: #0e0d15;
  --md-ref-palette-neutral-variant5: #111018;
  --md-ref-palette-neutral-variant6: #13121a;
  --md-ref-palette-neutral-variant10: #1c1b23;
  --md-ref-palette-neutral-variant12: #201f27;
  --md-ref-palette-neutral-variant17: #2a2931;
  --md-ref-palette-neutral-variant20: #312f38;
  --md-ref-palette-neutral-variant22: #35343c;
  --md-ref-palette-neutral-variant24: #3a3841;
  --md-ref-palette-neutral-variant25: #3c3a43;
  --md-ref-palette-neutral-variant30: #47464f;
  --md-ref-palette-neutral-variant35: #53515a;
  --md-ref-palette-neutral-variant40: #5f5d67;
  --md-ref-palette-neutral-variant50: #787680;
  --md-ref-palette-neutral-variant60: #928f99;
  --md-ref-palette-neutral-variant70: #adaab4;
  --md-ref-palette-neutral-variant80: #c9c5d0;
  --md-ref-palette-neutral-variant87: #dcd8e3;
  --md-ref-palette-neutral-variant90: #e5e1ec;
  --md-ref-palette-neutral-variant92: #ebe6f2;
  --md-ref-palette-neutral-variant94: #f0ecf7;
  --md-ref-palette-neutral-variant95: #f3effa;
  --md-ref-palette-neutral-variant96: #f6f2fd;
  --md-ref-palette-neutral-variant98: #fcf8ff;
  --md-ref-palette-neutral-variant99: #fffbff;
  --md-ref-palette-neutral-variant100: #ffffff;
  /* error */
  --md-ref-palette-error0: #000000;
  --md-ref-palette-error4: #280001;
  --md-ref-palette-error5: #2d0001;
  --md-ref-palette-error6: #310001;
  --md-ref-palette-error10: #410002;
  --md-ref-palette-error12: #490002;
  --md-ref-palette-error17: #5c0004;
  --md-ref-palette-error20: #690005;
  --md-ref-palette-error22: #710005;
  --md-ref-palette-error24: #790006;
  --md-ref-palette-error25: #7e0007;
  --md-ref-palette-error30: #93000a;
  --md-ref-palette-error35: #a80710;
  --md-ref-palette-error40: #ba1a1a;
  --md-ref-palette-error50: #de3730;
  --md-ref-palette-error60: #ff5449;
  --md-ref-palette-error70: #ff897d;
  --md-ref-palette-error80: #ffb4ab;
  --md-ref-palette-error87: #ffcfc9;
  --md-ref-palette-error90: #ffdad6;
  --md-ref-palette-error92: #ffe2de;
  --md-ref-palette-error94: #ffe9e6;
  --md-ref-palette-error95: #ffedea;
  --md-ref-palette-error96: #fff0ee;
  --md-ref-palette-error98: #fff8f7;
  --md-ref-palette-error99: #fffbff;
  --md-ref-palette-error100: #ffffff;
  /* light */
  --md-sys-color-primary-light: #5b53a8;
  --md-sys-color-on-primary-light: #ffffff;
  --md-sys-color-primary-container-light: #e4dfff;
  --md-sys-color-on-primary-container-light: #160362;
  --md-sys-color-primary-fixed-light: #e4dfff;
  --md-sys-color-on-primary-fixed-light: #160362;
  --md-sys-color-primary-fixed-dim-light: #c6c0ff;
  --md-sys-color-on-primary-fixed-variant-light: #433b8e;
  --md-sys-color-secondary-light: #5f5c71;
  --md-sys-color-on-secondary-light: #ffffff;
  --md-sys-color-secondary-container-light: #e4dff9;
  --md-sys-color-on-secondary-container-light: #1b192c;
  --md-sys-color-secondary-fixed-light: #e4dff9;
  --md-sys-color-on-secondary-fixed-light: #1b192c;
  --md-sys-color-secondary-fixed-dim-light: #c8c3dc;
  --md-sys-color-on-secondary-fixed-variant-light: #474459;
  --md-sys-color-tertiary-light: #7b5266;
  --md-sys-color-on-tertiary-light: #ffffff;
  --md-sys-color-tertiary-container-light: #ffd8e8;
  --md-sys-color-on-tertiary-container-light: #2f1122;
  --md-sys-color-tertiary-fixed-light: #ffd8e8;
  --md-sys-color-on-tertiary-fixed-light: #2f1122;
  --md-sys-color-tertiary-fixed-dim-light: #ebb8cf;
  --md-sys-color-on-tertiary-fixed-variant-light: #613b4e;
  --md-sys-color-error-light: #ba1a1a;
  --md-sys-color-error-container-light: #ffdad6;
  --md-sys-color-on-error-light: #ffffff;
  --md-sys-color-on-error-container-light: #410002;
  --md-sys-color-background-light: #fffbff;
  --md-sys-color-on-background-light: #1c1b1f;
  --md-sys-color-outline-light: #787680;
  --md-sys-color-inverse-on-surface-light: #f4eff4;
  --md-sys-color-inverse-surface-light: #313034;
  --md-sys-color-inverse-primary-light: #c6c0ff;
  --md-sys-color-shadow-light: #000000;
  --md-sys-color-surface-tint-light: #5b53a8;
  --md-sys-color-outline-variant-light: #c9c5d0;
  --md-sys-color-scrim-light: #000000;
  --md-sys-color-surface-light: #fcf8fd;
  --md-sys-color-on-surface-light: #1c1b1f;
  --md-sys-color-surface-variant-light: #e5e1ec;
  --md-sys-color-on-surface-variant-light: #47464f;
  /* --md-sys-color-surface-container-highest-light: #e5e1e6; */
  --md-sys-color-surface-container-highest-light: #e1dae1;
  --md-sys-color-surface-container-high-light: #ebe7ec;
  --md-sys-color-surface-container-light: #f1ecf1;
  --md-sys-color-surface-container-low-light: #f7f2f7;
  --md-sys-color-surface-container-lowest-light: #ffffff;
  --md-sys-color-surface-dim-light: #ddd9dd;
  --md-sys-color-surface-bright-light: #fcf8fd;
  /* dark */
  --md-sys-color-primary-dark: #c6c0ff;
  --md-sys-color-on-primary-dark: #2c2276;
  --md-sys-color-primary-container-dark: #433b8e;
  --md-sys-color-on-primary-container-dark: #e4dfff;
  --md-sys-color-primary-fixed-dark: #e4dfff;
  --md-sys-color-on-primary-fixed-dark: #160362;
  --md-sys-color-primary-fixed-dim-dark: #c6c0ff;
  --md-sys-color-on-primary-fixed-variant-dark: #433b8e;
  --md-sys-color-secondary-dark: #c8c3dc;
  --md-sys-color-on-secondary-dark: #302e41;
  --md-sys-color-secondary-container-dark: #474459;
  --md-sys-color-on-secondary-container-dark: #e4dff9;
  --md-sys-color-secondary-fixed-dark: #e4dff9;
  --md-sys-color-on-secondary-fixed-dark: #1b192c;
  --md-sys-color-secondary-fixed-dim-dark: #c8c3dc;
  --md-sys-color-on-secondary-fixed-variant-dark: #474459;
  --md-sys-color-tertiary-dark: #ebb8cf;
  --md-sys-color-on-tertiary-dark: #482537;
  --md-sys-color-tertiary-container-dark: #613b4e;
  --md-sys-color-on-tertiary-container-dark: #ffd8e8;
  --md-sys-color-tertiary-fixed-dark: #ffd8e8;
  --md-sys-color-on-tertiary-fixed-dark: #2f1122;
  --md-sys-color-tertiary-fixed-dim-dark: #ebb8cf;
  --md-sys-color-on-tertiary-fixed-variant-dark: #613b4e;
  --md-sys-color-error-dark: #ffb4ab;
  --md-sys-color-error-container-dark: #93000a;
  --md-sys-color-on-error-dark: #690005;
  --md-sys-color-on-error-container-dark: #ffdad6;
  --md-sys-color-background-dark: #1c1b1f;
  --md-sys-color-on-background-dark: #e5e1e6;
  --md-sys-color-outline-dark: #928f99;
  --md-sys-color-inverse-on-surface-dark: #1c1b1f;
  --md-sys-color-inverse-surface-dark: #e5e1e6;
  --md-sys-color-inverse-primary-dark: #5b53a8;
  --md-sys-color-shadow-dark: #000000;
  --md-sys-color-surface-tint-dark: #c6c0ff;
  --md-sys-color-outline-variant-dark: #47464f;
  --md-sys-color-scrim-dark: #000000;
  --md-sys-color-surface-dark: #141316;
  --md-sys-color-on-surface-dark: #c9c5ca;
  --md-sys-color-surface-variant-dark: #47464f;
  --md-sys-color-on-surface-variant-dark: #c9c5d0;
  --md-sys-color-surface-container-highest-dark: #353438;
  --md-sys-color-surface-container-high-dark: #2a292d;
  --md-sys-color-surface-container-dark: #201f23;
  --md-sys-color-surface-container-low-dark: #1c1b1f;
  /* --md-sys-color-surface-container-lowest-dark: #0e0e11; */
  --md-sys-color-surface-container-lowest-dark: #141419;
  --md-sys-color-surface-dim-dark: #141316;
  --md-sys-color-surface-bright-dark: #3a393c;
  /* display - large */
  --md-sys-typescale-display-large-font-family-name: Roboto;
  --md-sys-typescale-display-large-font-family-style: Regular;
  --md-sys-typescale-display-large-font-weight: 400px;
  --md-sys-typescale-display-large-font-size: 57px;
  --md-sys-typescale-display-large-line-height: 64px;
  --md-sys-typescale-display-large-letter-spacing: -0.25px;
  /* display - medium */
  --md-sys-typescale-display-medium-font-family-name: Roboto;
  --md-sys-typescale-display-medium-font-family-style: Regular;
  --md-sys-typescale-display-medium-font-weight: 400px;
  --md-sys-typescale-display-medium-font-size: 45px;
  --md-sys-typescale-display-medium-line-height: 52px;
  --md-sys-typescale-display-medium-letter-spacing: 0px;
  /* display - small */
  --md-sys-typescale-display-small-font-family-name: Roboto;
  --md-sys-typescale-display-small-font-family-style: Regular;
  --md-sys-typescale-display-small-font-weight: 400px;
  --md-sys-typescale-display-small-font-size: 36px;
  --md-sys-typescale-display-small-line-height: 44px;
  --md-sys-typescale-display-small-letter-spacing: 0px;
  /* headline - large */
  --md-sys-typescale-headline-large-font-family-name: Roboto;
  --md-sys-typescale-headline-large-font-family-style: Regular;
  --md-sys-typescale-headline-large-font-weight: 400px;
  --md-sys-typescale-headline-large-font-size: 32px;
  --md-sys-typescale-headline-large-line-height: 40px;
  --md-sys-typescale-headline-large-letter-spacing: 0px;
  /* headline - medium */
  --md-sys-typescale-headline-medium-font-family-name: Roboto;
  --md-sys-typescale-headline-medium-font-family-style: Regular;
  --md-sys-typescale-headline-medium-font-weight: 400px;
  --md-sys-typescale-headline-medium-font-size: 28px;
  --md-sys-typescale-headline-medium-line-height: 36px;
  --md-sys-typescale-headline-medium-letter-spacing: 0px;
  /* headline - small */
  --md-sys-typescale-headline-small-font-family-name: Roboto;
  --md-sys-typescale-headline-small-font-family-style: Regular;
  --md-sys-typescale-headline-small-font-weight: 400px;
  --md-sys-typescale-headline-small-font-size: 24px;
  --md-sys-typescale-headline-small-line-height: 32px;
  --md-sys-typescale-headline-small-letter-spacing: 0px;
  /* body - large */
  --md-sys-typescale-body-large-font-family-name: Roboto;
  --md-sys-typescale-body-large-font-family-style: Regular;
  --md-sys-typescale-body-large-font-weight: 400px;
  --md-sys-typescale-body-large-font-size: 16px;
  --md-sys-typescale-body-large-line-height: 24px;
  --md-sys-typescale-body-large-letter-spacing: 0.5px;
  /* body - medium */
  --md-sys-typescale-body-medium-font-family-name: Roboto;
  --md-sys-typescale-body-medium-font-family-style: Regular;
  --md-sys-typescale-body-medium-font-weight: 400px;
  --md-sys-typescale-body-medium-font-size: 14px;
  --md-sys-typescale-body-medium-line-height: 20px;
  --md-sys-typescale-body-medium-letter-spacing: 0.25px;
  /* body - small */
  --md-sys-typescale-body-small-font-family-name: Roboto;
  --md-sys-typescale-body-small-font-family-style: Regular;
  --md-sys-typescale-body-small-font-weight: 400px;
  --md-sys-typescale-body-small-font-size: 12px;
  --md-sys-typescale-body-small-line-height: 16px;
  --md-sys-typescale-body-small-letter-spacing: 0.4px;
  /* label - large */
  --md-sys-typescale-label-large-font-family-name: Roboto;
  --md-sys-typescale-label-large-font-family-style: Medium;
  --md-sys-typescale-label-large-font-weight: 500px;
  --md-sys-typescale-label-large-font-size: 14px;
  --md-sys-typescale-label-large-line-height: 20px;
  --md-sys-typescale-label-large-letter-spacing: 0.1px;
  /* label - medium */
  --md-sys-typescale-label-medium-font-family-name: Roboto;
  --md-sys-typescale-label-medium-font-family-style: Medium;
  --md-sys-typescale-label-medium-font-weight: 500px;
  --md-sys-typescale-label-medium-font-size: 12px;
  --md-sys-typescale-label-medium-line-height: 16px;
  --md-sys-typescale-label-medium-letter-spacing: 0.5px;
  /* label - small */
  --md-sys-typescale-label-small-font-family-name: Roboto;
  --md-sys-typescale-label-small-font-family-style: Medium;
  --md-sys-typescale-label-small-font-weight: 500px;
  --md-sys-typescale-label-small-font-size: 11px;
  --md-sys-typescale-label-small-line-height: 16px;
  --md-sys-typescale-label-small-letter-spacing: 0.5px;
  /* title - large */
  --md-sys-typescale-title-large-font-family-name: Roboto;
  --md-sys-typescale-title-large-font-family-style: Regular;
  --md-sys-typescale-title-large-font-weight: 400px;
  --md-sys-typescale-title-large-font-size: 22px;
  --md-sys-typescale-title-large-line-height: 28px;
  --md-sys-typescale-title-large-letter-spacing: 0px;
  /* title - medium */
  --md-sys-typescale-title-medium-font-family-name: Roboto;
  --md-sys-typescale-title-medium-font-family-style: Medium;
  --md-sys-typescale-title-medium-font-weight: 500px;
  --md-sys-typescale-title-medium-font-size: 16px;
  --md-sys-typescale-title-medium-line-height: 24px;
  --md-sys-typescale-title-medium-letter-spacing: 0.15px;
  /* title - small */
  --md-sys-typescale-title-small-font-family-name: Roboto;
  --md-sys-typescale-title-small-font-family-style: Medium;
  --md-sys-typescale-title-small-font-weight: 500px;
  --md-sys-typescale-title-small-font-size: 14px;
  --md-sys-typescale-title-small-line-height: 20px;
  --md-sys-typescale-title-small-letter-spacing: 0.1px;
}
